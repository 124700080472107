.check-wrapper {
    background: no-repeat url("./background.jpg");
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    display: flex;
    font-family: "Roboto";
    height: 100vh;
    position: relative;
    width: 100vw;
    overflow-x: hidden;
}

.check-block {
    align-items: center;
    background: #000000c4;
    border-radius: 8px;
    color: white;
    display: flex;
    flex-direction: column;
    font-size: 18px;
    margin: auto;
    min-height: 480px;
    padding: 8px;
    text-align: center;
    width: 50%;

    @media only screen and (max-width: 1024px) {
        width: 70%;
    }

    @media only screen and (max-width: 720px) {
        width: 90%;
    }
}

.check-block__input {
    background: transparent;
    border-radius: 4px;
    border: 1px solid white;
    color: white;
    font-size: 24px;
    line-height: 48px;
    margin-top: 8px;
    text-align: center;
    width: 80%;
    &:focus {
        outline: none;
        border-radius: 4px;
        border: 1px solid rgb(117, 201, 250);
    }
}

.check-block__results {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.check-block__ticket {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: auto;
    margin-bottom: 0;
    text-align: left;
}

.check-block__wait-icon {
    background: url("./clock.png") no-repeat;
    background-position: center;
    background-size: contain;
    height: 52px;
    margin: 0 auto 4px auto;
    width: 52px;
}

.check-block__results .event__numbers {
    color: black;

    @media only screen and (max-width: 720px) {
        transform: scale(0.7);
    }
}
