body {
  background-color: #999999;
  height: 100%;
  margin: 0;
  outline: none;
  overflow-x: hidden;
  padding: 0;
  user-select: none;
  width: 100%;
}
