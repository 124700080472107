.results-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
  padding: 6px;
}

.event {
  background: #49346b;
  border-radius: 4px;
  border: 1px solid white;
  flex: 1 1 40%;
  margin: 10px;
  padding: 4px;
}

.event__game-title {
  color: white;
  font-family: "Arial Black", "Arial Bold", Gadget, sans-serif;
  margin: 0 0 10px 0;
  text-align: center;
}

.event__numbers {
  display: flex;
  div:not(:first-child) {
    margin-left: 4px;
  }
}
