.event__numbers-ball {
  background: #004e99;
  border-radius: 50%;
  box-shadow: 10px 15px 20px -10px rgba(0, 0, 0, 0.4);
  height: 60px;
  width: 60px;
  position: relative;

  :not(:first-child) {
    margin-left: 2px;
  }

  div {
    background: #fff;
    border-radius: 50%;
    font-size: 20px;
    font-weight: bold;
    height: 30px;
    left: 14px;
    line-height: 30px;
    position: absolute;
    text-align: center;
    top: 10px;
    width: 30px;
  }

  &-blue {
    background: radial-gradient(circle at 20px 20px, #09f, #001);
  }

  &-red {
    background: radial-gradient(circle at 20px 20px, #f30, #001);
  }

  &-green {
    background: radial-gradient(circle at 20px 20px, #0f4, #001);
  }

  &-yellow {
    background: radial-gradient(circle at 20px 20px, #fc0, #001);
  }

  &-pirple {
    background: radial-gradient(circle at 20px 20px, #650076, #001);
  }
}
